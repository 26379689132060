<template>
  <div class="search">
    <div class="searchDiv">
      <div class="searchInput">
        <nut-searchbar
          v-model="value"
          placeholder="请输入搜索内容"
          :autofocus="true"
          @change="changeValue"
        ></nut-searchbar>
        <img src="../assets/search.png" alt="" />
        <div class="cancel" @click="back()">取消</div>
      </div>
    </div>

    <div class="searchCon" v-if="goodsList != ''">
      <div class="title">作品</div>
      <div class="zpList">
        <div
          class="zpItem"
          @click="goSearch(item)"
          v-for="item in goodsList"
          :key="item.id"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getSearch } from "../api/index.js";

export default {
  setup() {},
  data() {
    return {
      value: "",
      goodsList: [],
    };
  },
  methods: {
    changeValue() {
      let params = {
        search: this.value,
      };
      getSearch(params).then((res) => {
        this.goodsList = res.data.goods;
      });
    },
    goSearch(item) {
      this.$router.push({
        path: `/collection`,
        query: {
          id: item.id,
          name: item.name,
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.search {
  padding: 15px;
  .searchDiv {
    width: 100%;
    background-color: #f4f5f9;
    position: fixed;
    top: 0;
    left: 0;
    height: 52px;
    .searchInput {
      width: 345px;
      position: relative;
      margin: 0 auto;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nut-searchbar {
        // width: 310px;
        flex: 1;
        padding: 0;
        background: unset;
        margin-right: 5px;
      }
      .nut-searchbar__search-input {
        background: #e9eaec;
        padding-left: 38px;
        height: 35px;
        font-size: 12px;
      }
      .nut-searchbar__search-input .nut-searchbar__input-inner input {
        font-size: 12px;
        padding-left: 0;
      }
      img {
        position: absolute;
        left: 15px;
        width: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .cancel {
      font-size: 14px;
      flex-shrink: 0;
    }
  }

  .searchCon {
    margin-top: 48px;
    .title {
      font-size: 13px;
      color: #999999;
      margin-left: 6px;
    }
    .zpList {
      margin-left: 38px;
      margin-top: 9px;
    }
    .zpItem {
      font-size: 14px;
      color: #333333;
      margin-bottom: 10px;
    }
  }
}
</style>